import React from 'react';
import { Link } from 'react-router-dom';

const GenericNavigationBar: React.FC = () => {
    return (
        <header className="container-fluid" >
            <br />
            <div className="row">
                <div className="col-12 col-md">
                    <Link to="/">
                        <img src='/logo.svg' alt="Logo" style={{marginBottom: 20}} />
                    </Link>
                </div>
                <div className="col-12 col-md" style={{ textAlign: 'right' }}>
                    <Link to="/patient">
                        <button className="btn btn-primary rounded-pill text-white" style={{ fontSize: 16, backgroundColor: '#594157', borderColor: '#594157', marginRight: '1rem' }}>É um paciente?</button>
                    </Link>
                    <Link to="/professional">
                        <button className="btn btn-primary rounded-pill text-white" style={{ fontSize: 16, backgroundColor: '#594157', borderColor: '#594157' }}>É um profissional de saúde?</button>
                    </Link>
                </div>
            </div>
        </header>
    )
}

export default GenericNavigationBar;