import React from 'react';
import FAQ from './Faq';
import SubscribeModal from 'components/SubscribeModal';
import { subscribePatient } from 'api/brevo/api';

const MainSection: React.FC = () => {
  return (
    <section>
      <div className="container-fluid" style={{ backgroundColor: '#DAD4EF' }}>
        <HeaderContent />
      </div>

      <div className="container-fluid mt-4">
        <WhyJoining />
      </div>

      <div className="container-fluid" style={{ backgroundColor: '#25A18E' }}>
        <HowItWorks />
      </div>

      <div className="container-fluid">
        <TypesOfSpecialists />
      </div>

      <div className="container-fluid" style={{ backgroundColor: '#DAD4EF' }}>
        <FAQContent />
      </div>

    </section >
  );
};

const HowItWorks: React.FC = () => {
  return (
    <div className="container">
      <br /><br />
      <div className="row">
        <div className="col-12 col-md-1" />
        <div className="col-12 col-md align-items-center text-center override-confortaa">
          <p className="override-confortaa" style={{ fontSize: 32, color: '#fff', fontWeight: 'bold' }}>Como funciona?</p>
        </div>
        <div className="col-12 col-md-1" />
      </div>
      <div className="row text-white">
        <div className="col-12 col-md-1" />
        <div className="col-12 col-md d-flex flex-column align-items-center text-center">
          <span className="rounded-circle d-flex align-items-center justify-content-center" style={{ width: 57, height: 57, color: '#25A18E', backgroundColor: '#D4C5C7' }}>
            1
          </span>
          <br />
          <p className='override-confortaa' style={{ fontSize: 20, fontWeight: 'bold' }}>Marque a sua consulta</p>
          <p style={{ fontSize: 20 }}>Não precisa de se registar, basta deixar o seu contacto.</p>
        </div>
        <div className="col-12 col-md d-flex flex-column align-items-center text-center">
          <span className="rounded-circle d-flex align-items-center justify-content-center" style={{ width: 57, height: 57, color: '#25A18E', backgroundColor: '#D4C5C7' }}>
            2
          </span>
          <br />
          <p className='override-confortaa' style={{ fontSize: 20, fontWeight: 'bold' }}>Encontre um dentista</p>
          <p style={{ fontSize: 20 }}>Pode pesquisar por especialidade, região, ou nome.</p>
        </div>
        <div className="col-12 col-md-1" />
      </div>
      <br /><br />
    </div>
  )
}

const TypesOfSpecialists: React.FC = () => {
  return (
    <div className="container">
      <br /><br />
      <div className="row">
        <div className="col-12 col-md-1" />
        <div className="col-12 col-md" >
          <p className='override-confortaa' style={{ fontSize: 32, fontWeight: 'bold', color: '#594157' }}>Especialidades</p>
          <p style={{ fontSize: 20 }}>Pode procurar profissionais generalistas ou especialistas em diversas áreas.</p>
        </div>
        <div className="col-12 col-md" >
          <div className="p-4 mb-3" style={{ backgroundColor: '#DAD4EF' }}>
            <p className='override-confortaa' style={{ fontSize: 20, fontWeight: 'bold', color: '#2C242B' }}>Cirurgia oral</p>
            <p style={{ fontSize: 20 }}>Diagnóstico e tratamento cirúrgico das doenças, lesões e anomalias dos dentes, boca, maxilares e estruturas anexas.</p>
            {/* 
            <Link to="/directory">
              <button className="btn btn-primary rounded-pill text-white" style={{ fontSize: 20, backgroundColor: '#594157', borderColor: '#594157' }}>Encontrar especialista</button>
            </Link>
          */}
          </div>
          <div className="p-4 mb-3" style={{ backgroundColor: '#DAD4EF' }}>
            <p className='override-confortaa' style={{ fontSize: 20, fontWeight: 'bold', color: '#2C242B' }}>Periodontologia</p>
            <p style={{ fontSize: 20 }}>Especialidade que se dedica ao estudo, diagnóstico e tratamento das gengivas e osso.</p>
            {/* 
<Link to="/directory">
              <button className="btn btn-primary rounded-pill text-white" style={{ fontSize: 20, backgroundColor: '#594157', borderColor: '#594157' }}>Encontrar especialista</button>
            </Link>
                      */}

          </div>
        </div>
        <div className="col-12 col-md" >
          <div className="p-4 mb-3" style={{ backgroundColor: '#DAD4EF' }}>
            <p className='override-confortaa' style={{ fontSize: 20, fontWeight: 'bold', color: '#2C242B' }}>Ortodontia</p>
            <p style={{ fontSize: 20 }}>Especialidade que corrige a posição dos dentes e dos ossos maxilares posicionados de forma inadequada.</p>
            {/* 

            <Link to="/directory">
              <button className="btn btn-primary rounded-pill text-white" style={{ fontSize: 20, backgroundColor: '#594157', borderColor: '#594157' }}>Encontrar especialista</button>
            </Link>
                                  */}

          </div>
          <div className="p-4 mb-3" style={{ backgroundColor: '#DAD4EF' }}>
            <p className='override-confortaa' style={{ fontSize: 20, fontWeight: 'bold', color: '#2C242B' }}>Odontopediatria</p>
            <p style={{ fontSize: 20 }}>Área da medicina dentária dedicada à saúde oral de bebés, crianças e adolescentes.</p>
            {/* 

            <Link to="/directory">
              <button className="btn btn-primary rounded-pill text-white" style={{ fontSize: 20, backgroundColor: '#594157', borderColor: '#594157' }}>Encontrar especialista</button>
            </Link>
                                              */}

          </div>
        </div>
        <div className="col-12 col-md-1" />
      </div>
      <br /><br />

    </div>
  )
}

export const FAQContent: React.FC = () => {
  return (
    <div className="container">
      <br /><br />
      <div className="row">
        <div className="col-12 col-md-1" />
        <div className="col-12 col-md-4" >
          <p className='override-confortaa' style={{ fontSize: 32, fontWeight: 'bold', color: '#594157' }}>Perguntas frequentes</p>
          <p style={{ fontSize: 20 }}>Não encontra o que procura? Envie-nos uma mensagem com a sua questão e responderemos com a maior brevidade possível.</p>
        </div>
        <div className="col-12 col-md-6" >
          <FAQ />
        </div>
        <div className="col-12 col-md-1" />
      </div>
      <br /><br />
    </div>
  )
}

const WhyJoining: React.FC = () => {
  return (
    <div className="container">
      <br /><br />
      <div className="row">
        <div className="col-12 col-md-1" />
        <div className="ccol-12 col-md align-items-center text-center" style={{ marginTop: 25 }}>
          <div className="image-container">
            <img src='./assets/clock-regular.svg' alt="Sempre disponível" className="img-fluid" />
          </div>
          <p></p>
          <p className='override-confortaa'>Sempre disponível</p>
          <p>Marque a sua consulta facilmente a qualquer hora do dia, 7 dias por semana</p>
        </div>
        <div className="col-12 col-md-1" />
        <div className="col-12 col-md align-items-center text-center" style={{ marginTop: 25 }}>
          <div className="image-container">
            <img src='./assets/handshake-regular.svg' alt="Sempre disponível" className="img-fluid" />
          </div>
          <p></p>
          <p className='override-confortaa'>Sem complicações</p>
          <p>Escolha a hora que mais lhe convém, sem negociações nem compromissos</p>
        </div>
        <div className="col-12 col-md-1" />
        <div className="col-12 col-md align-items-center text-center" style={{ marginTop: 25 }}>
          <div className="image-container">
            <img src='./assets/award-solid.svg' alt="O profissional ideal" className="img-fluid" />
          </div>
          <p></p>
          <p className='override-confortaa'>O profissional ideal</p>
          <p>Encontre rapidamente um dentista na sua zona que responda às suas necessidades</p>
        </div>
        <div className="col-12 col-md-1" />
      </div>
      <br /><br />
    </div>
  )
}

const HeaderContent: React.FC = () => {

  return (
    <div className="container">
      <br /><br />
      <div className="row align-items-center">
        <div className="col-12 col-md-1" />
        <div className="col-12 col-md-5">
          <p className='override-confortaa' style={{ fontSize: 40, color: '#594157', fontWeight: 'bold', }}>Marque consultas a qualquer hora</p>
          <p style={{ fontSize: 24, color: '#594157' }}>Em breve terá acesso a uma lista de profissionais perto de si e poderá marcar consultas de qualquer especialidade online.</p>
          <SubscribeModal buttonText='Subscrever newsletter' modalTitle='Subscrever newsletter' confirmButtonText='Registar' onSubscribe={subscribePatient} />
        </div>
        <div className="col-12 col-md-5" style={{ textAlign: 'center', marginTop: 20 }}>
          <img
            src="./assets/patient-header-image.svg"
            alt=""
            style={{ maxWidth: '100%' }}
          />
        </div>
        <div className="col-12 col-md-1" />

      </div>
      <br /><br />
    </div>
  )
}

export default MainSection;

