import React from 'react';
import FAQ from './Faq';
import { Tabs, Tab } from 'react-bootstrap';
import SubscribeModal from 'components/SubscribeModal';
import { subscribeDoctor } from 'api/brevo/api';



const MainSection: React.FC = () => {
  return (
    <section>

      <div className="container-fluid" style={{ backgroundColor: '#2C242B' }}>
        <HeaderContent />
      </div>
      <div className="container-fluid" style={{ backgroundColor: '#25A18E' }}>
        <HowItWorks />
      </div>
      <div className="container-fluid" style={{ backgroundColor: '#1E1E1E' }}>
        <JoinNow />
      </div>
      <div className="container-fluid" style={{ backgroundColor: '#2C242B' }}>
        <WhyJoining />
      </div>
      <div className="container-fluid" style={{ backgroundColor: '#1E1E1E' }}>
        <QuestionsAndAnswer />
      </div>

    </section >
  );
};

const HeaderContent: React.FC = () => {
  return (
    <div className="container">
      <br /><br />
      <br /><br />

      <div className="row align-items-center">
        <div className="col-12 col-md-1" />
        <div className="col-12 col-md-5">
          <p className='override-confortaa' style={{ fontSize: 40, color: '#fff ', fontWeight: 'bold', }}>A sua agenda como nunca a viu</p>
          <p style={{ fontSize: 24, color: '#fff' }}>Optimize os seus processos, marcações disponíveis 24h/dia, e combata o absentismo</p>
          <SubscribeModal buttonText='Entrar na lista de espera' modalTitle='Registo na lista de espera' confirmButtonText='Fazer pedido' onSubscribe={subscribeDoctor} />

        </div>
        <div className="col-12 col-md-5" style={{ textAlign: 'center', marginTop: 20 }}>
          <img
            src="./assets/specialist-header-image.svg"
            alt=""
            style={{ maxWidth: '100%' }}
          />
        </div>
        <div className="col-12 col-md-1" />

      </div>
      <br /><br />
      <br /><br />

    </div>
  )
}

const HowItWorks: React.FC = () => {
  return (
    <div className="container">
      <br /><br />
      <div className="row">
        <div className="col-12 col-md-1" />
        <div className="col align-items-center text-center override-confortaa">
          <p className="override-confortaa" style={{ fontSize: 32, color: '#fff', fontWeight: 'bold' }}>Como funciona?</p>
        </div>
        <div className="col-12 col-md-1" />
      </div>
      <div className="row text-white">
        <div className="col-12 col-md-1" />
        <div className="col-12 col-md d-flex flex-column align-items-center text-center" style={{ marginTop: 25 }}>
          <span className="rounded-circle d-flex align-items-center justify-content-center" style={{ width: 57, height: 57, color: '#25A18E', backgroundColor: '#D4C5C7' }}>
            1
          </span>
          <br />
          <p className='override-confortaa' style={{ fontSize: 20, fontWeight: 'bold' }}>Registe-se na plataforma</p>
          <p style={{ fontSize: 20 }}>Crie o seu perfil e escolha as suas definições</p>
        </div>
        <div className="col-12 col-md d-flex flex-column align-items-center text-center" style={{ marginTop: 25 }}>
          <span className="rounded-circle d-flex align-items-center justify-content-center" style={{ width: 57, height: 57, color: '#25A18E', backgroundColor: '#D4C5C7' }}>
            2
          </span>
          <br />
          <p className='override-confortaa' style={{ fontSize: 20, fontWeight: 'bold' }}>Importe o seu calendário</p>
          <p style={{ fontSize: 20 }}>Todas as suas consultas serão migradas para o DoctorFind</p>
        </div>
        <div className="col-12 col-md d-flex flex-column align-items-center text-center" style={{ marginTop: 25 }}>
          <span className="rounded-circle d-flex align-items-center justify-content-center" style={{ width: 57, height: 57, color: '#25A18E', backgroundColor: '#D4C5C7' }}>
            3
          </span>
          <br />
          <p className='override-confortaa' style={{ fontSize: 20, fontWeight: 'bold' }}>Partilhe o seu perfil</p>
          <p style={{ fontSize: 20 }}>Mais pacientes vão descobri-lo e marcar consultas facilmente</p>
        </div>
        <div className="col-12 col-md-1" />
      </div>
      <br /><br />
    </div>
  )
}

const JoinNow: React.FC = () => {
  return (
    <div className="container">
      <br /><br />
      <div className="row">
        <div className="col-1" />
        <div className="col align-items-center text-center override-confortaa">
          <p className="override-confortaa" style={{ fontSize: 32, color: '#DAD4EF', fontWeight: 'bold' }}>Registe-se já!</p>
          <p style={{ fontSize: 20, color: '#fff' }}>As primeiras pessoas a registarem-se terão vantagens aquando do lançamento do produto!</p>
          <br />
          <SubscribeModal buttonText='Entrar na lista de espera' modalTitle='Registo na lista de espera' confirmButtonText='Fazer pedido' onSubscribe={subscribeDoctor} />
        </div>
        <div className="col-1" />
      </div>

      <br /><br />
    </div>
  )
}

function WhyJoiningTabs() {

  const tabContent = (text: string, imagePath: string) => {
    return (
      <div className="row">
        <div className="col-12 col-md-1" />
        <div className="col-12 col-md align-items-center override-confortaa rounded" style={{ backgroundColor: '#594157' }}>
          <div className="row" style={{ margin: 5, marginTop: 25 }}>
            <div className="col-12 col-lg-8 d-flex align-items-center ml-lg-auto">
              <p className="text-justify" style={{ fontSize: 20, color: '#FFFFFF', fontFamily: 'Jost', textAlign: 'left' }}>
                {text}
              </p>
            </div>
            <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center ml-lg-auto">
              <img
                src={imagePath}
                alt=""
                style={{ maxWidth: '100%' }}
              />
            </div>

          </div>
        </div>
        <div className="col-12 col-md-1" />
      </div>
    )
  }

  return (
    <Tabs
      defaultActiveKey="1"
      id="uncontrolled-tab-example"
      className="custom-tabs col-12 col-md d-flex justify-content-center nav-pills"
      style={{ borderBottom: 0, marginBottom: 5, }}
    >
      <Tab eventKey="1" title="Assiduidade" className='custom-tabs'>
        {tabContent("As consultas marcadas com antecedência (consultas sem urgência) são as mais sujeitas a serem esquecidas. Para isso o envio de SMS vai relembrar da consulta e vai permitir ao paciente apresentar-se a horas e dar a possibilidade de anular, para que esse período possa ser ocupado por outro paciente (ex: uma urgência).", "./assets/absentismo.svg")}
      </Tab>
      <Tab eventKey="2" title="Flexibilidade" className='custom-tabs'>
        {tabContent("Não importa a que horas do dia ou da noite, o paciente pode fazer marcação de consulta, sem ter de esperar pelo dia seguinte para ligar para a clinica, poupando assim tempo de telefone a assistente. Mesmo em período de férias da clinica.", "./assets/disponibilidade.svg")}
      </Tab>
      <Tab eventKey="3" title="Visibilidade" className='custom-tabs'>
        {tabContent("Ao procurar um dentista na plataforma DoctorFind pelo código-postal, os dentistas mais próximos do paciente vão aparecer automaticamente, assim como as próximas disponibilidades. Uma urgência = um novo paciente.", "./assets/visibilidade.svg")}
      </Tab>
    </Tabs>
  );
}

const WhyJoining: React.FC = () => {
  return (
    <div className="container">
      <br /><br />
      <div className="row">
        <div className="col-12 col-md-1" />
        <div className="col-12 col-md align-items-center text-center override-confortaa">
          <p className="override-confortaa" style={{ fontSize: 32, color: '#DAD4EF', fontWeight: 'bold' }}>Vantagens para o seu negócio</p>
        </div>
        <div className="col-12 col-md-1" />
      </div>
      <div className="row">
        <div className="col-12 col-md align-items-center text-center override-confortaa">
          <WhyJoiningTabs />
        </div>
      </div>

      <br /><br />
    </div>
  )
}

export const QuestionsAndAnswer: React.FC = () => {
  return (
    <div className="container">
      <br /><br />
      <div className="row">
        <div className="col-12 col-md-1" />
        <div className="col-12 col-md-4" >
          <p className='override-confortaa' style={{ fontSize: 32, fontWeight: 'bold', color: '#DAD4EF' }}>Perguntas frequentes</p>
          <p style={{ fontSize: 20, color: '#ffffff' }}>Não encontra o que procura? Envie-nos uma mensagem com a sua questão e responderemos com a maior brevidade possível.</p>
        </div>
        <div className="col-12 col-md-6" >
          <FAQ />
        </div>
        <div className="col-12 col-md-1" />
      </div>
      <br /><br />
    </div>
  )
}


export default MainSection;
