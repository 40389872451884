import React from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <header className="container-fluid" style={{ backgroundColor: '#2C242B' }}>
      <br />
      <div className="row">
        <div className="col">
          <Link to="/professional">
            <img src='./logo-dark.svg' alt="Logo" />
          </Link>
        </div>
        <div className="col" style={{ textAlign: 'right' }}>
          <Link to="/">
            <button className="btn btn-primary rounded-pill" style={{ fontSize: 16, backgroundColor: '#DAD4EF', borderColor: '#DAD4EF', color: '#2C242B' }}>É um paciente?</button>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
