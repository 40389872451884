import axios, { AxiosError } from 'axios';

const API_KEY = process.env.REACT_APP_BREVO_API_KEY;
// Replace with your Sendinblue list ID
const PATIENT_LIST_ID: number = 4;
const DOCTOR_LIST_ID: number = 3;


export async function subscribePatient(email: string): Promise<void> {
  subscribeCustomerToList(email, PATIENT_LIST_ID);
}

export async function subscribeDoctor(email: string): Promise<void> {
  subscribeCustomerToList(email, DOCTOR_LIST_ID);
}

async function addContact(email: string): Promise<void> {
  const data = {
    email: email,
    emailBlacklisted: false,
    smsBlacklisted: false,
    updateEnabled: true
  };

  await axios
    .post('https://api.brevo.com/v3/contacts', data, {
      headers: {
        'accept': 'application/json',
        'api-key': API_KEY,
        'content-type': 'application/json',
      },
    })
    .then((response) => {
      console.log('Successfully created a new contact', response.data);
    })
    .catch((error) => {
      console.error('Failed to create new contact:', error.response.data);
    });
}

async function subscribeCustomerToList(email: string, listId: number): Promise<void> {
  await addContact(email);
  try {
    await axios.post(
      `https://api.brevo.com/v3/contacts/lists/${listId}/contacts/add`,
      {
        "emails": [
          email
        ]
      },
      {
        headers: {
          'api-key': API_KEY,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('Successfully subscribed customer:', email);
  } catch (error) {
    const axiosError = error as AxiosError;

    if (axiosError.response) {
      console.error(
        'Failed to subscribe customer:',
        axiosError.response.data
      );
    } else {
      console.error('Error occurred:', axiosError.message);
    }
  }
}
