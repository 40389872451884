import React from 'react';
import Footer from './Footer';
import GenericNavigationBar from './GenericNavigationBar';



export const TermsConditions: React.FC = () => {
    return (
        <div>
            <GenericNavigationBar />
            <br /> <br />
            <div className="container">
                <div className="row">

                    <div className="col-12 col-md-1" />
                    <div className="col-12 col-md" >
                        <h1>Termos e Condições</h1>
                        <br />
                        <div className="mb-4">
                            <h2>1. Introdução</h2>
                            <p>
                                Bem-vindo ao nosso website que lista profissionais de saúde e permite o agendamento de consultas.
                                Ao utilizar o nosso website, o(a) utilizador(a) concorda em cumprir com estes Termos e Condições.
                                Por favor, leia-os atentamente antes de utilizar o site.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2>2. Utilização do Site</h2>
                            <p>
                                O nosso website serve apenas como um meio de informação e agendamento de consultas médicas.
                                Não nos responsabilizamos pela qualidade do serviço prestado pelos profissionais de saúde registados.
                                É responsabilidade do(a) utilizador(a) avaliar e selecionar o profissional de saúde adequado às suas necessidades.
                            </p>
                            <p>
                                Além disso, o(a) utilizador(a) concorda em não utilizar o nosso website para qualquer finalidade ilegal ou não autorizada,
                                incluindo, mas não se limitando a:
                            </p>
                            <ul>
                                <li>Tentar obter acesso não autorizado ao nosso sistema</li>
                                <li>Interferir com a segurança do nosso website</li>
                                <li>Transmitir qualquer vírus ou código malicioso que possa afetar a funcionalidade do nosso website</li>
                                <li>Utilizar o nosso website para enviar spam ou qualquer outra forma de comunicação não solicitada</li>
                            </ul>
                        </div>

                        <div className="mb-4">
                            <h2>3. Agendamento de Consultas</h2>
                            <p>
                                O nosso website permite o agendamento de consultas médicas com os profissionais registados.
                                No entanto, não garantimos a disponibilidade do profissional ou a precisão das informações fornecidas pelos mesmos.
                            </p>
                            <p>
                                O agendamento de consultas está sujeito à disponibilidade dos profissionais de saúde.
                                Não nos responsabilizamos por consultas canceladas ou reagendadas por motivos alheios ao nosso controlo.
                            </p>
                            <p>
                                Ao agendar uma consulta, o(a) utilizador(a) concorda em fornecer informações precisas e atualizadas sobre si mesmo(a).
                                É sua responsabilidade manter as suas informações atualizadas para garantir a precisão do agendamento.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2>4. Cancelamento de Consultas</h2>
                            <p>
                                Não nos responsabilizamos por quaisquer perdas ou danos decorrentes de consultas canceladas.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2>5. Privacidade</h2>
                            <p>
                                Respeitamos a sua privacidade e os seus dados pessoais serão tratados de forma confidencial.
                                Ao utilizar o nosso site, o(a) utilizador(a) concorda com a nossa Política de Privacidade.
                            </p>
                            <p>
                                Ao utilizar o nosso website, o(a) utilizador(a) concorda em receber e-mails relacionados com o agendamento de consultas e suporte ao cliente.
                                Caso não queira receber esses e-mails, pode cancelá-los a qualquer momento.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2>6. Propriedade Intelectual</h2>
                            <p>
                                O conteúdo do nosso website, incluindo textos, imagens e marca registada, são propriedade exclusiva da DoctorFind.
                                É expressamente proibido copiar, reproduzir ou utilizar qualquer conteúdo sem autorização prévia.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2>7. Modificações nos Termos e Condições</h2>
                            <p>
                                Reservamos o direito de modificar estes Termos e Condições a qualquer momento e sem aviso prévio.
                                É responsabilidade do(a) utilizador(a) verificar periodicamente se houve alguma atualização.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2>8. Limitação de Responsabilidade</h2>
                            <p>
                                Não garantimos a disponibilidade ou a precisão das informações fornecidas pelos profissionais de saúde registados no nosso site.
                                Não nos responsabilizamos por quaisquer perdas ou danos decorrentes do uso do nosso website.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2>9. Indemnização</h2>
                            <p>
                                O(a) utilizador(a) concorda em indemnizar e manter a nossa empresa, seus afiliados, licenciadores e prestadores de serviços,
                                bem como os seus respectivos diretores, funcionários, agentes e representantes,
                                livres de quaisquer reclamações, responsabilidades, danos, custos e despesas, incluindo honorários advocatícios razoáveis,
                                decorrentes do seu uso do nosso website ou da violação destes Termos e Condições.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2>10. Disposições Gerais</h2>
                            <p>
                                Estes Termos e Condições constituem o acordo completo entre o(a) utilizador(a) e a nossa empresa e regem a utilização do nosso website.
                                Se qualquer disposição destes Termos e Condições for considerada inválida ou inexequível, tal disposição será anulada e as demais disposições permanecerão em pleno vigor e efeito.
                            </p>
                            <p>
                                A nossa falha em exigir qualquer direito ou disposição destes Termos e Condições não será considerada uma renúncia a esses direitos.
                            </p>
                        </div>

                        <div>
                            <h2>11. Lei Aplicável e Jurisdição</h2>
                            <p>
                                Estes Termos e Condições são regidos pelas leis de Portugal.
                                Qualquer disputa relacionada com o website ou aos seus Termos e Condições será resolvida pelos tribunais de Portugal.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-1" />
                </div>
            </div>
            <Footer />
        </div>);
};

const Privacy: React.FC = () => {
    let emailAddress = "test@maillinator.com"
    return (
        <div>
            <GenericNavigationBar />
            <br /><br />
            <div className="container">
                <div className="row">

                    <div className="col-12 col-md-1" />
                    <div className="col-12 col-md" >
                        <h1>Política de Privacidade</h1>
                        <br />
                        <div className="mb-4">
                            <h2>Que dados pessoais são recolhidos</h2>
                            <ul>
                                <li>Nomes e sobrenomes</li>
                                <li>Endereços de e-mail</li>
                                <li>Números de telefone</li>
                                <li>Data de nascimento</li>
                                <li>Profissão</li>
                                <li>Fotos</li>
                                <li>Informações de consulta médica</li>
                            </ul>
                        </div>

                        <div className="mb-4">
                            <h2>Como são usados os dados pessoais</h2>
                            <p>
                                Os seus dados pessoais são usados para fornecer os nossos serviços e melhorar a experiência do utilizador no nosso site.
                                Além disso, podemos usar esses dados para:
                            </p>
                            <ul>
                                <li>Entrar em contacto com o(a) utilizador(a) para fornecer informações sobre os nossos serviços</li>
                                <li>Personalizar a sua experiência no nosso site</li>
                                <li>Garantir a segurança e proteção dos nossos utilizadores</li>
                            </ul>
                        </div>

                        <div className="mb-4">
                            <h2>Proteção dos dados pessoais</h2>
                            <p>
                                Nós levamos a segurança dos seus dados pessoais muito a sério e tomamos todas as precauções razoáveis para os proteger.
                                Implementamos medidas de segurança técnicas e organizacionais para proteger os seus dados pessoais contra acesso não autorizado,
                                perda acidental ou destruição, danos, uso ou divulgação.
                                Nós também treinamos a nossa equipa a manter a privacidade e segurança dos seus dados pessoais.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2>Partilha dos dados pessoais</h2>
                            <p>
                                Não partilhamos os seus dados pessoais com terceiros, exceto quando exigido por lei ou para garantir a segurança e proteção dos nossos utilizadores.
                                Por exemplo, poderemos compartilhar os seus dados pessoais com autoridades policiais ou judiciais se tal for exigido por lei.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2>Os seus direitos</h2>
                            <p>
                                O(a) utilizador(a) tem o direito de solicitar uma cópia dos dados pessoais que mantemos sobre ele(a).
                                Além disso, se o(a) utilizador(a) acredita que quaisquer informações que temos sobre ele(a) estão incorretas ou incompletas,
                                pode solicitar que as corrijamos.
                                O(a) utilizador(a) também tem o direito de nos pedir para eliminar os seus dados pessoais dos nossos sistemas.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2>Uso de cookies</h2>
                            <p>
                                Nós usamos cookies para melhorar a sua experiência no nosso site.
                                Os cookies são pequenos ficheiros que são armazenados no seu dispositivo quando acede ao nosso site.
                                Esses ficheiros ajudam-nos a personalizar a sua experiência e fornecer informações sobre como cada utilizador usa o nosso site.
                                O(a) utilizador(a) pode optar por desativar os cookies no seu browser, mas isso pode afetar a sua experiência no nosso site.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2>Alterações à Política de Privacidade</h2>
                            <p>
                                Podemos alterar esta Política de Privacidade a qualquer momento.
                                A versão mais recente será sempre a que está disponível no nosso site.
                                Se fizermos alterações significativas a esta política, notificaremos os utilizadores por meio de um aviso no nosso site ou por e-mail.
                            </p>
                        </div>

                        <div>
                            <h2>Contacto</h2>
                            <p>
                                Se tiver alguma dúvida sobre esta Política de Privacidade, entre em contato connosco através do e-mail <strong>{emailAddress}</strong>.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-1" />
                </div>
            </div>
            <Footer />
        </div>);
};

export default Privacy;