import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

interface FAQItem {
    question: string;
    answer: string;
}

const FAQ: React.FC = () => {
    return (
        <Accordion>
            {faqData.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header className="override-confortaa" style={{ fontSize: 20 }}>{item.question}</Accordion.Header>
                    <Accordion.Body style={{ fontSize: 20 }}>{item.answer}</Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

const faqData: FAQItem[] = [
    {
        question: 'Quanto custa uma marcação online?',
        answer: 'As consultas são presenciais, e o preço de cada uma depende do profissional e da área; pode ver os preços das consultas na página de cada profissional. Para os pacientes, a utilização do DoctorFind é totalmente gratuita, por isso não existem custos acrescidos nem taxas escondidas: o que vê é o que paga.',
    },
    {
        question: 'Como sei que o profissional que escolhi é de confiança?',
        answer: 'Todos os profissionais registados no nosso site são obrigados a fornecer o número de inscrição na Ordem dos Médicos Dentistas, pelo que são garantidamente pessoas qualificadas. Pode também pesquisar qualquer profissional no site da Ordem.',
    },
    {
        question: 'Como é que posso ter a certeza que a minha consulta ficou marcada?',
        answer: 'Assim que a sua marcação for registada, enviar-lhe-emos um email automaticamente a confirmar a data, o profissional, e o local da sua consulta.',
    },
    {
        question: 'Como é que posso cancelar uma consulta?',
        answer: 'No email de confirmação da consulta encontrará instruções para a cancelar ou remarcar. Basta clicar no link e seguir as indicações.',
    },
];

export default FAQ;