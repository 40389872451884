import './professional.css';

import React from "react";
import Header from "./Header";
import MainSection from "./MainSection";
import Footer from "pages/Footer";

const ProfressionaltHome: React.FC = () => {
    return (
        <div>
          <Header />
          <MainSection />
          <Footer backgroundColor='#2C242B' textColor='#fff' />
        </div>
      )
}

export default ProfressionaltHome;