import React from 'react';

interface FooterProps {
  backgroundColor?: string
  textColor?: string
}

const Footer: React.FC<FooterProps> = ({ backgroundColor = '#FFFFFF', textColor = '#000000' }) => {
  return (
    <div className="container-fluid" style={{ backgroundColor }}>
      <br />
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 border-top">
        <p className="col-12 col-md-4 mb-0" style={{ color: textColor }}>© 2023 DoctorFind, Inc</p>


        <ul className="nav col-12 col-md-8 justify-content-end">
          <li className="nav-item"><a href="/" className="nav-link px-2" style={{ color: textColor }}>Home</a></li>
          <li className="nav-item"><a href="/patient" className="nav-link px-2" style={{ color: textColor }}>Paciente</a></li>
          <li className="nav-item"><a href="/professional" className="nav-link px-2" style={{ color: textColor }}>Profissional de Saúde</a></li>
          <li className="nav-item"><a href="/blog" className="nav-link px-2" style={{ color: textColor }}>Blog</a></li>
          <li className="nav-item"><a href="/privacy" className="nav-link px-2" style={{ color: textColor }}>Privacidade</a></li>
          <li className="nav-item"><a href="/tanc" className="nav-link px-2" style={{ color: textColor }}>Termos e Condições</a></li>
        </ul>

        <p className="col-12 col-md-4 mb-0" ><a href="https://storyset.com/people" className="nav-link px-1" style={{ fontSize: 10, color: textColor }}>People illustrations by Storyset</a></p>
      </footer>
    </div>);
};

export default Footer;