
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProfressionaltHome from 'pages/professional/ProfressionalHome';
import PatientHome from 'pages/patients/PatientHome';
import Privacy, { TermsConditions } from 'pages/Legal';
import EspecialidadesDentaria from 'pages/blog/EspecialidadesDentaria';
import Halitose from 'pages/blog/Halitose';
import BlogMain from 'pages/blog/Main';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route >
          <Route path="professional" element={<ProfressionaltHome />} />
          <Route path="blog/halitose" element={<Halitose />} />
          <Route path="blog/especialidadesdentaria" element={<EspecialidadesDentaria />} />
          <Route path="blog/*" element={<BlogMain />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="tanc" element={<TermsConditions />} />
          <Route path="*" element={<PatientHome />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;