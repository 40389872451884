import React from "react";
import Header from "./Header";
import MainSection from "./MainSection";
import './patient.css';
import Footer from "pages/Footer";

const PatientHome: React.FC = () => {
    return (
        <div>
          <Header />
          <MainSection />
          <Footer />
        </div>
      )
}

export default PatientHome;
