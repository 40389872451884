import Footer from 'pages/Footer';
import GenericNavigationBar from 'pages/GenericNavigationBar';
import React from 'react';
import BlogList from './BlogList';

const EspecialidadesDentaria: React.FC = () => {
    return (
        <div>
            <GenericNavigationBar />
            <br />
            <br />
            <div className="container">
                <div className="row">

                    <div className="col-12 col-md-1" />
                    <div className="col-12 col-md" >

                        <h1>Medicina Dentária</h1>
                        <img src="/assets/blog/especialidadedentista_1.jpg" alt="Medicina Dentária" className="img-fluid" />
                        <p>
                            A medicina dentária é uma área da saúde que se dedica a prevenir, diagnosticar e tratar doenças e problemas relacionados com a cavidade oral, incluindo os dentes, as gengivas, a língua e os ossos maxilares. Não se trata apenas de cuidar da estética dentária, mas sim da saúde oral, que tem um impacto significativo na saúde geral do corpo humano. Neste artigo, vamos abordar as quatro grandes especialidades da medicina dentária.
                        </p>

                        <h2>Odontopediatria</h2>
                        <p>
                            A odontopediatria é uma das grandes especialidades da medicina dentária, que se ocupa dos bebés, das crianças e dos adolescentes. Esta especialidade é de extrema importância, uma vez que qualquer tipo de experiência negativa num gabinete dentário em criança pode ser traumatizante para o resto da vida da pessoa, fazendo com que se desenvolva um medo incontrolável da cadeira do dentista. Por isso, é essencial que as crianças sejam acolhidas e tratadas por um especialista com uma equipa competente e com experiência (o que inclui simpatia e bom humor). Isso vai promover um cuidado da parte da criança com a sua higiene oral, que poderá ter repercussões positivas para toda a vida.
                        </p>

                        <h2>Cirurgia Oral</h2>
                        <p>
                            A cirurgia oral é uma especialidade da medicina dentária que engloba tratamentos de diferentes tipos, desde a cirurgia dentoalveolar (que inclui extrações dentárias) até à colocação de implantes endósseos. Esta especialidade também aborda a cirurgia pré-protética, a patologia oral e medicina oral, cirurgia periodontal, cirurgia regenerativa, trauma crânio-maxilo-facial, tratamento de doenças e distúrbios temporomandibulares, tumores benignos e malignos da cavidade oral e estruturas anexas, e tratamento de infecções envolvendo tecidos moles e ósseos na região da cabeça e pescoço.
                        </p>

                        <h2>Ortodontia</h2>
                        <p>
                            A ortodontia é a especialidade da medicina dentária que corrige o alinhamento de dentes e ossos maxilares mal posicionados. É responsável por tratar problemas como os dentes tortos ou apinhados, mordida cruzada, mordida aberta, mordida profunda e dentes separados. Os benefícios gerais do tratamento ortodôntico incluem a diminuição de cáries, hálito saudável, melhoria da autoestima, melhorias na fala, diminuição das dores de cabeça e prevenção de perda dos dentes.
                        </p>

                        <img src="/assets/blog/especialidadedentista_2.jpg" alt="Medicina Dentária" className="img-fluid" />

                        <h2>Periodontologia</h2>
                        <p>
                            A periodontologia é a especialidade da medicina dentária que estuda e trata as doenças do sistema de implantação e suporte dos dentes. As doenças periodontais incluem todas as doenças que afetam os tecidos que sustentam os dentes, sendo frequentemente causadas por bactérias. O objetivo desta especialidade é diagnosticar e tratar gengivites e periodontites, assim como as suas sequelas, como por exemplo recessão gengival, mobilidade dentária e perda óssea.
                        </p>

                        <h2>Conclusão</h2>
                        <p>
                            A medicina dentária é uma área da saúde que é frequentemente subvalorizada, mas que é crucial para manter uma boa saúde oral e, consequentemente, uma boa saúde geral. As quatro grandes especialidades da medicina dentária, a odontopediatria, cirurgia oral, ortodontia e periodontologia, desempenham papéis fundamentais na prevenção e tratamento de problemas dentários e periodontais, além de contribuírem para uma melhor qualidade de vida dos pacientes.
                        </p>

                        <p>
                            É importante lembrar que a consulta regular com um dentista é fundamental para detetar problemas precocemente e prevenir futuros problemas mais graves. Seja qual for a especialidade, um bom dentista deve estar sempre atualizado com as técnicas mais modernas e ter uma abordagem humanizada com os pacientes, transmitindo confiança e segurança durante todo o tratamento.
                        </p>
                    </div>
                    <div className="col-12 col-md-1" />
                </div>
            </div>

            <BlogList ownUrl='/blog/especialidadesdentaria' />
            <Footer />
        </div>);
};

export default EspecialidadesDentaria;