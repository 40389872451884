import React from 'react';
import { Link } from 'react-router-dom';

interface BlogPostItem {
    title: string;
    image: string;
    cardText: string;
    articleUrl: string;
}

const blogPosts: BlogPostItem[] = [
    {
        title: 'Halitose',
        image: '/assets/blog/halitose_1.jpg',
        cardText: 'O mau hálito, também conhecido como halitose, é um problema bastante comum que pode ter várias causas, incluindo algumas patológicas.',
        articleUrl: '/blog/halitose'
    },
    {
        title: 'Medicina Dentária',
        image: '/assets/blog/especialidadedentista_1.jpg',
        cardText: 'A medicina dentária é uma área da saúde que se dedica a prevenir, diagnosticar e tratar doenças e problemas relacionados com a cavidade oral, incluindo os dentes, as gengivas, a língua e os ossos maxilares.',
        articleUrl: '/blog/especialidadesdentaria'
    },
]

const BlogPostItemContent: React.FC<{ title: string, image: string, cardText: string, articleUrl: string }> = ({ title, image, cardText, articleUrl }) => {
    return (
        <>
            <div className="col-12 col-md-4">
                <div className="card">
                    <img src={image} className="card-img-top" alt="Post 1" />
                    <div className="card-body">
                        <h5 className="card-title">{title}</h5>
                        <p className="card-text">{cardText}</p>
                        <Link to={articleUrl}>
                            <button className="btn btn-primary rounded-pill text-white" style={{ fontSize: 16, backgroundColor: '#594157', borderColor: '#594157' }}>Ler mais</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}



const BlogList: React.FC<{ ownUrl: string }> = ({ ownUrl }) => {
    return (
        <div>
            <div className="container">
                <div className="row">

                    <div className="col-12 col-md-1" />
                    <div className="col-12 col-md" >
                        <h1>Outros artigos</h1>
                        <br />
                        <div className="row">
                            {blogPosts.filter((item, index) => item.articleUrl !== ownUrl).map((item, index) => (
                                <BlogPostItemContent title={item.title} image={item.image} cardText={item.cardText} articleUrl={item.articleUrl} />
                            ))}
                        </div>
                    </div>
                    <div className="col-12 col-md-1" />
                </div>
                <div className="col-12 col-md-1" />

            </div>
        </div>);
};



export default BlogList;