import React, { useState } from 'react';

interface SubscriptionFormInput {
  buttonText: string;
  modalTitle: string;
  confirmButtonText: string;
  onSubscribe: (email: string) => Promise<void>;
}

const SubscriptionForm: React.FC<SubscriptionFormInput> = ({ buttonText, modalTitle, confirmButtonText, onSubscribe }) => {
  const [email, setEmail] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleSubmit = async () => {
    // Perform subscription logic here
    console.log('Subscribing user with email:', email);
    await onSubscribe(email);
    // Reset form
    setEmail('');
    setIsChecked(false);

    // Close the modal
    setModalVisible(false);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBackdropClick = () => {
    console.log("test");
    closeModal();
  };

  const isFormValid = email.trim().length > 0 && isChecked;

  return (
    <div>
      <button className="btn btn-primary rounded-pill" onClick={openModal} style={{ fontSize: 20, backgroundColor: '#25A18E', borderColor: '#25A18E' }}>
        {buttonText}
      </button>

      {modalVisible && (
        <div style={{ textAlign: 'left' }}>
          <div className="modal fade show" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="row">
                  <div className="col-10" >
                    <p className="override-confortaa" style={{ paddingTop: 15, paddingLeft: 25, fontSize: 30, color: '#594157', fontWeight: 'bold' }}>
                      {modalTitle}
                    </p>
                  </div>
                  <div className="col-2" >
                    <div style={{ paddingTop: 5, marginRight: 5, textAlign: 'right' }}>
                      <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                  </div>
                </div>
                <div className="modal-body" style={{ marginLeft: 10, marginRight: 10 }}>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <div className="mb-3">
                          <label htmlFor="emailInput" className="override-jost" style={{ fontSize: 20, color: '#594157' }}>
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="emailInput"
                            value={email}
                            onChange={handleEmailChange}
                            style={{ backgroundColor: '#D9D9D9' }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">

                        <input
                          className="form-check-input form-check-input-lg"
                          type="checkbox"
                          id="emailCheckbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          style={{ backgroundColor: '#D9D9D9', minWidth: 40, height: 40, }}
                        />
                      </div>
                      <div className="col d-flex align-items-center" style={{textAlign: 'left'}}>

                        <label className="form-check-label" style={{ fontSize: 13, color: '#000000' }} htmlFor="emailCheckbox">
                          Quero receber emails periódicos do DoctorFind com novidades e aceito os&nbsp;
                          <a style={{ color: '#594157', fontWeight: 'bold' }} href="/tanc">
                            termos e condições
                          </a>
                          &nbsp;e&nbsp;
                          <a style={{ color: '#594157', fontWeight: 'bold' }} href="/privacy">
                            política de privacidade
                          </a>
                          .
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12" style={{ marginTop: 5 }}>
                        <button
                          type="button"
                          className="btn btn-primary mx-auto d-block w-100"
                          disabled={!isFormValid}
                          onClick={handleSubmit}
                          style={{ fontSize: 20, backgroundColor: '#594157', borderColor: '#594157' }}
                        >
                          {confirmButtonText}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop show" onClick={handleBackdropClick}></div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionForm;
