import Footer from 'pages/Footer';
import GenericNavigationBar from 'pages/GenericNavigationBar';
import React from 'react';
import BlogList from './BlogList';

const Halitose: React.FC = () => {
    return (
        <div>
            <GenericNavigationBar />
            <br />
            <br />
            <div className="container">
                <div className="row">

                    <div className="col-12 col-md-1" />
                    <div className="col-12 col-md" >
                        <h1>Mau Hálito</h1>
                        <img src="/assets/blog/halitose_1.jpg" alt="Mau Hálito" className="img-fluid" />

                        <p>
                            O mau hálito, também conhecido como halitose, é um problema bastante comum que pode ter várias causas, incluindo algumas patológicas. Em 85 a 90% dos casos, a halitose é causada pela proliferação de bactérias na boca, principalmente na língua, que abriga 60% das bactérias da boca. Estima-se que a língua seja responsável por quase metade dos casos de halitose.
                        </p>

                        <h2>Outras causas da halitose</h2>
                        <ul>
                            <li>Infecções buco-dentárias, como gengivite, aftas, parodontite e abcesso dentário;</li>
                            <li>Cáries dentárias;</li>
                            <li>Tabagismo;</li>
                            <li>Alimentos que contenham derivados de enxofre, como cebola, alho, repolho, caril, proteínas em pó, entre outros;</li>
                            <li>Boca seca;</li>
                            <li>Infecções da garganta (como angina), amígdalas ou seios nasais, ou mesmo bronquite;</li>
                            <li>Certas doenças gastrointestinais, como doença do refluxo gastroesofágico;</li>
                            <li>Distúrbios metabólicos, como diabetes;</li>
                            <li>Insuficiência renal crónica;</li>
                            <li>Caseum.</li>
                        </ul>

                        <img src="/assets/blog/halitose_2.jpg" alt="Mau Hálito" className="img-fluid" />

                        <h2>Como prevenir o mau hálito</h2>
                        <ul>
                            <li>Escovar os dentes e passar o fio dentário diariamente;</li>
                            <li>Escovar a língua ou usar raspadores de língua;</li>
                            <li>Hidratar-se adequadamente;</li>
                            <li>Evitar alimentos que contenham derivados de enxofre;</li>
                            <li>Evitar o tabaco;</li>
                            <li>Fazer visitas regulares ao dentista.</li>
                        </ul>

                        <h2>Quando procurar ajuda médica</h2>
                        <p>
                            Se o mau hálito persistir mesmo depois de melhorar a higiene bucal e adotar outras medidas preventivas, é recomendável procurar ajuda médica. Em alguns casos, a halitose pode ser um sintoma de um problema de saúde subjacente, como distúrbios gastrointestinais ou insuficiência renal crónica. Nesses casos, o tratamento da halitose depende da causa subjacente. Portanto, é importante consultar um médico para um diagnóstico preciso e um tratamento adequado.
                        </p>


                    </div>
                    <div className="col-12 col-md-1" />
                </div>
            </div>

            <BlogList ownUrl='/blog/halitose' />
            <Footer />
        </div>);
};

export default Halitose;