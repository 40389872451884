import Footer from 'pages/Footer';
import GenericNavigationBar from 'pages/GenericNavigationBar';
import React from 'react';
import BlogList from './BlogList';
import { Link } from 'react-router-dom';

const BlogMain: React.FC = () => {
    return (
        <div>
            <GenericNavigationBar />
            <br />
            <br />
            <div className="container">
                <div className="row">

                    <div className="col-12 col-md-1" />
                    <div className="col-12 col-md" >
                        <div className="card">
                            <img src="/assets/blog/halitose_1.jpg" alt="Mau Hálito" className="img-fluid" />
                            <div className="card-body">
                                <h5 className="card-title">Halitose</h5>
                                <p className="card-text">                            O mau hálito, também conhecido como halitose, é um problema bastante comum que pode ter várias causas, incluindo algumas patológicas. Em 85 a 90% dos casos, a halitose é causada pela proliferação de bactérias na boca, principalmente na língua, que abriga 60% das bactérias da boca. Estima-se que a língua seja responsável por quase metade dos casos de halitose.
                                </p>
                                <Link to="/blog/halitose">
                                    <button className="btn btn-primary rounded-pill text-white" style={{ fontSize: 16, backgroundColor: '#594157', borderColor: '#594157' }}>Ler mais</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-1" />

                </div>
                <br />
                <br />
                <BlogList ownUrl='/blog/halitose' />
                <Footer />
            </div>
        </div>);
};

export default BlogMain;